$primary: #fee1f2;
$primary-emphasis: #ffacdf;
$primary-emphasis-hover: #fda0d0;
$secondary: #998398;
$secondary-emphasis: #9c9a9ac4;

$danger: #ff4a4a;

$backgroud: #1f101f;
$backgroud-emphasis: #1b0e1b;
$backgroud-fade: #280028;
$hover: #d9d9d9e6;
