@import "./colors.scss", "./mixins.scss";

#Maintenance {
  @include flex(center, center, column);
  height: 100dvh;
  background: linear-gradient($backgroud 10%, $backgroud-fade 80%);
  position: relative;
  overflow: hidden;

  .dixie {
    width: 10em;
    height: 10em;
    margin-bottom: 30px;
    z-index: 2;
  }

  .transdixie {
    width: 40em;
    height: 40em;
    z-index: 1;
    position: absolute;
    left: 2%;
  }

  .ellipsa {
    width: 12em;
    height: 12em;
    z-index: 1;
    position: absolute;
  }

  .e1 {
    right: 20%;
    top: 15%;
  }

  .e2 {
    right: -4%;
    top: 55%;
  }

  .e3 {
    right: 22%;
    bottom: -6%;
  }

  h1 {
    font-size: 3em;
    line-height: 1em;
    margin-bottom: 6px;
    color: $primary;

    .intro {
      color: $primary;
    }

    .name {
      color: $primary-emphasis;
    }
  }

  P {
    text-align: left;
    color: $secondary;
    font-size: 2.2em;
    margin-bottom: 20px;
    font-weight: bold;
  }
}

@media (max-width: $mobile) {
  #Maintenance {
    .dixie {
      width: 9em;
      height: 9em;
      order: 3;
    }

    h1 {
      font-size: 1.7em;
    }

    p {
      font-size: 1.2em;
    }

    .transdixie {
      width: 25em;
      height: 25em;
      left: -30%;
      bottom: -4%;
    }

    .ellipsa {
      width: 6.5em;
      height: 6.5em;
    }

    .e1 {
      left: 9%;
      top: 15%;
    }

    .e2 {
      right: 25%;
      top: 3%;
    }

    .e3 {
      right: 7%;
      top: 28%;
    }
  }
}
