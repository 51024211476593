@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import "./colors.scss";

html {
  color-scheme: dark;
}

img {
  user-select: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
}

* {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

li {
  list-style: none;
}

body {
  overflow-x: hidden;
  background-color: $backgroud;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #1b1b1b;
}

::-webkit-scrollbar-thumb {
  background: #313131;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: #313131;
}
